import './Header.css'
import { Link } from 'react-router-dom';
import Appointments from './pages/appointments';
import Our_Team from './pages/our_team';
import TMJ_info from './pages/tmj_info';
import Sleep_apnea from './pages/sleep_apnea';
import Contact from './pages/contact';
import Navbar from './Navbarblack';
import Logo1 from './images/logo_final_blackborder.png';
import DrawerB from './component/Drawer_b';

 {/* <div className='contact-header'>
                        <h3>Call Today</h3>
                        <h3>760-770-4033</h3>
                    </div>
                    <div className='contact-header2'>
                        <h3>35900 Bob Hope Dr., Suite 110 </h3>
                        <h3>Rancho Mirage, CA 92270</h3>
                    </div> */}
export default function Header() {
    return (
        <div className='header1'>
            <div className='container1'>
                    <div className='mobile-header'>
                    <Link to='/'>
                        <div>
                            <img id='logo-final' src={Logo1} alt="head" />
                        </div>
                        <div className='logo-name1'>
                            <h1 id='logo-name1'>TMJ Head & Neck Pain Center</h1>
                            <h3 id='logo-subtitle1'>also treating sleep apnea</h3>
                        </div>
                    </Link>
                    </div>
                    
                   
                    <div className='nav'>
                    <div className='nav-sections1'>
                        
                    
                        <div>
                        <Link to='/tmj' element={<TMJ_info/>}><a id='tmj1'>TMJ</a></Link>
                        </div>
                        <div>
                        <Link to='/sleep_apnea' element={<Sleep_apnea/>}><a id='sleepapnea1'>Sleep Apnea</a></Link>
                        </div>
                        <div>
                        <Link to='/about_us' element={<Our_Team/>}><a id='ourteam1'>About Us</a></Link>
                        </div>
                        <div>
                        {/* <Link href='#bottom'><a>Contact</a></Link> */}
                        <Link to='/contact' element={<Contact/>}><a id='contact1'>Contact</a></Link>
                        </div>
                        <div>
                        {/* make this a button */}
                        {/* <Link to='/appointments' element={<Appointments/>} id='button-nav'>Appointments</Link> */}
                        <a href="https://app.nexhealth.com/appt/tmj-head-and-neck-pain-center" id='button-nav'>Appointments</a>
                        </div>
                         
                    </div>
                    <div className='navbar-home2'>
                    {/* <Navbar id='navbar-home2'></Navbar> */}
                        <DrawerB/>
                    </div>  
                    
                </div>
                
            </div>
        </div>
    );
}
