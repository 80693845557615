import React from "react";
import GoogleMapReact from 'google-map-react';
import './gmap.css';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function SimpleMap(){
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };

  return (

    // this is the website used to make the google map https://www.maps.ie/create-google-map/

    <div className="iframe">
        <iframe id='iframe'  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=35900%20Bob%20Hope%20Dr%20STE%20110,%20Rancho%20Mirage,%20CA%2092270+(TMJ%20Head%20&amp;%20Neck%20Pain%20Center)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
            <a href="https://www.maps.ie/population/">Calculate population in area</a>
        </iframe>
    </div>
  );
}


