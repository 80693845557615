import './App.css';

function App() {
  return (
    <>
    <form className='container'>
      <div className="Header">
        <h3>Today's Date: <input></input></h3>
      </div>
      <div className='patient'>
        <h4>Patient</h4>
        <h5>Patient's Legal Name:</h5><input></input><h5>Preferred Name</h5><input></input>
        <h5>Male</h5><input type="checkbox"></input><h5>Female</h5><input type="checkbox"></input>
      </div>
    </form>
    </>
  );
}

export default App;
