import { Route, Routes } from 'react-router-dom';
import Home from './home';
// import Appointments from './pages/appointments';
import OurTeam from './pages/our_team';
import TMJ_info from './pages/tmj_info';
import Sleep_apnea from './pages/sleep_apnea';
import Contact from './pages/contact';
// import Appointments_results from './pages/appointments_results';


function Nav () {
    return (
        <>
        <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/Appointments" element={<Appointments />} /> */}
            <Route path="/about_us" element={<OurTeam />} />
            <Route path="/tmj" element={<TMJ_info />} />
            <Route path="/sleep_apnea" element={<Sleep_apnea />} />
            <Route path="/contact" element={<Contact />} />
            {/* <Route path='/appointments_results' element={<Appointments_results/>} /> */}
        </Routes></>
    );
}

export default Nav; 