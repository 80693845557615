import './our_team.css'
import Footer from '../Footer';
import Header from '../Header';
import DrLee from '../images/Dr.Lee.jpg';
import { Link } from 'react-router-dom';
import OurTeamMore from './our_team_more';
import Appointments from './appointments';


function our_team () {
    return (
        <>
        <Header/>
        <div className='ourteam'>   
            <div className='ourteam-paragraph'>
                <div data-aos="fade-in" data-aos-duration="1500">
                    <h1 id='ourteam-title'>Dr. Hyung Jin Lee</h1>   
                    <h3 id='about-drlee'>Dr. Lee's interest in TMJ disorder grew while working as a general dentist.
    He saw his patients that had nowhere to go and get relief from their jaw pain, referred from specialists to specialists often without finding help.
    Dr. Lee dedicated his time in addition to working full time to go through 300+ hours of education courses around the country to learn more about the condition to help patients suffering from jaw pain and other related symptoms.</h3>
                </div>
                <div data-aos="fade-in" data-aos-duration="1500">
                    <h1 id='ourteam-title'>Educational Background:</h1>
                    <h3 id='about-drlee'>Loma Linda University School of Dentistry</h3>
                    <h1 id='ourteam-title'>Post-Doctoral Education</h1>
                    <h3 id='about-drlee'>American Academy of Dental Sleep Medicine
                    - Mastery courses
                    <h3 id='about-drlee'></h3>
                    University of California Los Angeles
                    - UCLA TMD and Orofacial Pain Mini Residency
                    <h3 id='about-drlee'></h3>
                    American Academy of Craniofacial Pain
                    - Fundamentals of TMD Diagnosis & Treatment
                    <h3 id='about-drlee'></h3>
                    Academy of Clinical Sleep Disorders Discipline
                    - Academic Certificate in Dental Sleep Medicine
                    <h3 id='about-drlee'></h3>
                    Tri-County Dental Society
                    - Diagnosis and Management of TDs and Occlusion</h3>
                </div>
                <div data-aos="fade-in" data-aos-duration="1500">
                    <h1 id='ourteam-title'>Certification and Membership</h1>
                    <h3 id='about-drlee'>Dental Board of California - Dental License
                    <h3 id='about-drlee'></h3>
                    Diplomate, American Board of Dental Sleep Medicine 
                    <h3 id='about-drlee'></h3>
                    American Academy of Orofacial pain - Active Member 
                    <h3 id='about-drlee'></h3>
                    Academy of Clinical Sleep Disorders Discipline
                    - Academic Certificate in Dental Sleep Medicine</h3>
                </div>
            </div>
            <div className='ourteam-pic'>
                <div className='drlee' data-aos="fade-in" data-aos-duration="1500">
                <img id='drlee' alt='dentist' src={DrLee}></img>
            </div>
                
            </div>
            {/* <Link to='/our_team_more' element={<OurTeamMore/>} className='ourteam-button'>Learn More</Link> */}
        </div>
        <Footer />
        </>
    );
}

export default our_team; 