import './contact.css';
import Footer from '../Footer';
import Header from '../Header';
import office from '../images/index_office.png';
import { useState, useRef } from 'react';
import Gmap from '../component/gmap';


export default function Contact () {


    return (
        <>
        <div>
            <Header/>
        </div>
        <div className='contact-body'>
            <div className='contact-container' data-aos="fade-in">
                <div className='contact-header1'>
                    <h1>Office Information</h1>
                </div>
                <div className='contact-times'>
                    <br />
                    <h4 id='contact-info1'>Contact:</h4>
                    <h3 id='contact-info1'>Office: 760-770-4033</h3>
                    <h3 id='contact-info1'>Fax: 760-770-3975</h3>
                    <br />
                    <hr />
                    <br />
                    <h4 id='contact-info1'>Rancho Mirage Location:</h4>
                    <h3 id='contact-info1'>35900 Bob Hope Dr., Suite 110</h3>
                    <h3 id='contact-info1'>Rancho Mirage, CA 92270</h3>
                    <br></br>
                    <h4 id='contact-info1'>Hours:</h4>                                      
                    <div className='contact-info-main'>
                        <h3 id='contact-info'>
                        Monday: 
                        </h3>
                        <h3>
                        8:00 AM - 5:00 PM
                        </h3>
                    </div>
                    <div className='contact-info-main'>
                        <h3 id='contact-info'>
                        Tuesday: 
                        </h3>
                        <h3>
                        8:00 AM - 5:00 PM
                        </h3>
                    </div>
                    <div className='contact-info-main'>
                        <h3 id='contact-info'>
                        Wednesday: 
                        </h3>
                        <h3>
                        By Appointment
                        </h3>
                    </div>
                    <div className='contact-info-main'>
                        <h3 id='contact-info'>
                        Thursday: 
                        </h3>
                        <h3>
                        8:00 AM - 5:00 PM
                        </h3>
                    </div>
                    <br/>
                    <hr />
                    <br />
                    <h4 id='contact-info1'>Redlands Location:</h4>
                    <h3 id='contact-info1'>2048 Orange Tree Ln., Ste 100</h3>
                    <h3 id='contact-info1'>Redlands, CA 92374</h3>
                    <br></br> 
                    <h4 id='contact-info1'>Hours:</h4>
                    <div className='contact-info-main'>
                        <h3 id='contact-info'>
                        Friday: 
                        </h3>
                        <h3>
                        By Appointment
                        </h3>
                    </div>
                    
                </div>
            </div>
            <div data-aos="fade-in">
                <img id='office-pic' alt='office location' src={office}></img>
            </div>
        </div>
        <div data-aos="fade-in">
            <Gmap />
        </div>
        {/* <div className='contact-pic'>
            <div class="custom-shape-divider-top-1687186481">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                </svg>
            </div>
            <h1></h1>
        </div> */}
        {/* <div>
            <Footer/>
        </div> */}
        </>
    );
}