import './appointments.css';
import * as React from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const ariaLabel = { 'aria-label': 'description' };

const preferred_time = [
    {
        value: 'Whatever gets me the fastest',
        label: 'Whatever gets me the fastest',
    },
    {
      value: 'Morning',
      label: 'Morning',
    },
    {
      value: 'Afternoon',
      label: 'Afternoon',
    },
    {
      value: 'Contact me to arrange',
      label: 'Contact me to arrange',
    },
  ];

const patient = [
    {
        value: 'New Patient',
        label: 'New Patient',
    },
    {
      value: 'Existing Patient',
      label: 'Existing Patient',
    },
  ];

  const insurance = [
    {
        value: 'Contact me to arrange',
        label: 'Contact me to arrange',
    },
    {
      value: 'Self-pay / Out-of-pocket',
      label: 'Self-pay / Out-of-pocket',
    },
    {
        value: 'My plan lets me choose any dentist',
        label: 'My plan lets me choose any dentist',
      },
    {
        value: 'HMO',
        label: 'HMO',
      },
      {
        value: 'PPO',
        label: 'PPO',
      },
      {
        value: "I'm not sure",
        label: "I'm not sure",
      },
      
  ];

  const referred = [
    {
        value: 'Web search',
        label: 'Web search',
    },
    {
      value: 'Social media',
      label: 'Social media',
    },
    {
        value: 'Family member',
        label: 'Family member',
      },
    {
        value: 'Friend',
        label: 'Friend',
      },
      {
        value: 'Other',
        label: 'Other',
      },
      
  ];



function Appointments () {
  const navigate = useNavigate();

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs.sendForm('service_o75uqel', 'template_gnqcb1f', form.current, 'cdb43HlhN9S2QwcmP')

      .then((result) => {
          console.log(result.text);
          navigate ('/appointments_results');
      }, (error) => {
          console.log(error.text);
      });
  };


    return (
        <>
        <form ref={form} onSubmit={sendEmail}>
        <Header/>
        <div class="custom-shape-divider-bottom-1686862665">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
</div>
        <div className='body-appointment'>
            <div className='body-header'>
                <div className='body-header1' data-aos="fade-in">
                    <h1 id='body-header1'>Book An Appointment</h1>
                    <h3>Request an appointment via secure email, please complete the following form. The appointment request will go straight to our front office team who will contact you within one business day to confirm the date and time requested.</h3>
                </div>
                <div data-aos="fade-in">
                    <h1 id='body-header1'>By phone</h1>
                    <h3>give us a call:</h3>
                    <br></br>
                    <h3>760-770-4033</h3>
                </div>
            </div>
          
            <div className='personal' data-aos="fade-in">
                <h1 id='title'>Personal Information</h1>
                <div className='preferred'>
                  <h3 id='subtitle'>Name:</h3>
                  <TextField id='name' name='name' placeholder="Name" variant="outlined"/>
                </div>
                <div className='preferred'>
                  <h3 id='subtitle'>Phone:</h3>
                  <TextField id='name' name='phone' placeholder="Phone" />
                </div>
                <div className='preferred'>
                  <h3 id='subtitle'>Email:</h3>
                  <TextField id='name' name='email' placeholder="Email" />
                </div>
            </div>
            <div className='body4' data-aos="fade-in">
                <h1 id='title'>Appointment Information</h1>
                <div className='preferred'>
                  <h3 id='subtitle'>Preferred Date:</h3>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateField']}>
                      <DateField id='date-picker' name='preferred_date' label="MM/DD/YYYY" />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div className='preferred' data-aos="fade-in">
                  <h3 id='subtitle'>Preferred Time:</h3>
                  <TextField name='preferred-time' id="preferred" select defaultValue='Whatever gets me the fastest'>
                      {preferred_time.map((option) => (
                          <MenuItem  key={option.value} value={option.value}>
                          {option.value}
                          </MenuItem>
                      ))}
                  </TextField>
                </div>
                <div className='preferred' data-aos="fade-in">
                  <div>
                    <h3 id='subtitle'>I am a:</h3>
                  </div>
                  <div>
                    <TextField name="patient-type" id="preferred" select defaultValue="New Patient">
                        {patient.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.value}
                            </MenuItem>
                        ))}
                    </TextField>
                  </div>
                </div>
                <div className='preferred' data-aos="fade-in">
                  <h3 id='subtitle'>Insurance:</h3>
                  <TextField name='insurance' id="insurance" select defaultValue="Self-pay / Out-of-pocket">
                      {insurance.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                          {option.value}
                          </MenuItem>
                      ))}
                  </TextField>
                </div>
                <div className='preferred' data-aos="fade-in">
                  <h3 id='subtitle'>Referred By:</h3>
                  <TextField name='referred' id="referred" select defaultValue="Web search">
                      {referred.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                          {option.value}
                          </MenuItem>
                      ))}
                  </TextField>
                </div>
                {/* <div className="preferred">
                  <h3 id="subtitle">Text Alert:</h3>
                  <h3>Agree to recieve text alerts</h3>
                  <input type="checkbox" />
                </div> */}
                <div className='preferred' data-aos="fade-in">
                  <h3 id='subtitle'>Message:</h3>
                  <textarea id="subtitle5" maxlength="300" rows='4' type="text" name='message' label="Message" />
                </div>
                <div className="preferred2">
                  <input name='checkbox' id='checkbox' type="checkbox" />
                  <h3>I agree to recieve text alerts from TMJ Head & Neck Pain Center</h3>
                </div>
              </div>
            <div className='preferred' data-aos="fade-in">
                <h3 id='subtitle'></h3>
            </div>
            <div className='submit-button' data-aos="fade-in">
              <input type='submit' value='Submit' id='submit-button'/>
            </div>
        </div>        
        <div className='footer'>
            <div>
                <div id='bottom' data-aos="fade-in">
                    <div className='logo-name1'>
                        <Link to='/'><h1 id='logo-name'>TMJ Head & Neck Pain Center</h1></Link>
                        <h3 id='logo-subtitle3'>also treating sleep apnea</h3>
                    </div>
                    <br />
                </div>
                <div className='footer-hours-time2' data-aos="fade-in">
                    <a>Office: 760-770-4033</a>
                    <a>Fax: 760-770-3975</a>
                </div>
                <div className='google-location' data-aos="fade-in">
                    <a id='bottom-font' href='https://www.google.com/maps/place/TMJ+Head+%26+Neck+Pain+Center/@33.7895759,-116.4075622,17z/data=!3m1!4b1!4m6!3m5!1s0x80dafd430cff43ff:0xe695f17553c469da!8m2!3d33.7895759!4d-116.4053735!16s%2Fg%2F1tjv3t8v?entry=ttu'><h5>35900 Bob Hope Dr., Suite 110</h5></a>
                    <a id='bottom-font' href='https://www.google.com/maps/place/TMJ+Head+%26+Neck+Pain+Center/@33.7895759,-116.4075622,17z/data=!3m1!4b1!4m6!3m5!1s0x80dafd430cff43ff:0xe695f17553c469da!8m2!3d33.7895759!4d-116.4053735!16s%2Fg%2F1tjv3t8v?entry=ttu'><h5>Rancho Mirage, CA 92270</h5></a>
                </div>
                <br></br>
                <div className='footer-hours' data-aos="fade-in">
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Monday:</h5>
                        </div>
                        <div>
                            <h5>8:00 AM - 5:00 PM</h5>
                        </div>
                    </div>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Tuesday:</h5>
                        </div>
                        <div>
                            <h5>8:00 AM - 5:00 PM</h5>
                        </div>
                    </div>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Wednesday:</h5>
                        </div>
                        <div>
                            <h5>By Appointment</h5>
                        </div>
                    </div>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Thursday:</h5>
                        </div>
                        <div>
                            <h5>8:00 AM - 5:00 PM</h5>
                        </div>
                    </div>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Friday:</h5>
                        </div>
                        <div>
                            <h5>Closed</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </form>
        </>
    );
}

export default Appointments; 