import './sleep_apnea.css';
import Footer from '../Footer';
import Header from '../Header';
import mouth from '../images/mouth_appliance.png';
import React, { useState } from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';
import Appointments from './appointments';
import { Link } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Sleep_apnea () {
    const [visible, setVisible] = useState(false)
        
        const toggleVisible = () => {
          const scrolled = document.documentElement.scrollTop;
          if (scrolled > 300){
            setVisible(true)
          } 
          else if (scrolled <= 300){
            setVisible(false)
          }
        };
        
        const scrollToTop = () =>{
          window.scrollTo({
            top: 0, 
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
          });
        
        window.addEventListener('scroll', toggleVisible);}
    return (
        <>
        <div>
            <Header/>
        </div>
        <div className='sleep-container' data-aos="fade-in">
        <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography><h1>Obstructive Sleep Apnea</h1></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <h3 id='tmj-paragraph'><strong>OBSTRUCTIVE SLEEP APNEA (OSA)</strong> is a breathing disorder in which the individual stops breathing for 10 seconds or longer during sleep. It is characterized by intermittent episodes of complete cessation of breathing due to a total airway collapse, despite a persistent effort to breathe. These pauses can occur 5 to 50 times or more an hour.
                When OSA occurs, the tongue falls back into the throat blocking the upper airway, causing airflow to stop. When breathing stops, a family member and/or bed partner hears the snoring broken by a pause until the sleeper gasps for air and awakens. During sleep, this abnormal breathing disrupts ventilation and reduces the amount of oxygen supplied to the blood, lungs, heart, and brain; inducing sleep fragment, disrupting normal sleep cycles, and creating various heart related diseases and general health problems.</h3>
                <h3 id='tmj-paragraph'>
                OSA is one of the most serious forms of sleep disordered breathing, affecting 15 to 20 million Americans, and a potentially life-threatening medical disorder which progressively worsens with age and weight gain.
                According to a report by the American Academy of Sleep Medicine, the most adverse effects of OSA are in the cardiovascular system: systemic hypertension, coronary heart disease, cardiac hypertrophy, cardiac arrhythmias, and increased cardiovascular morbidity/mortility.

                </h3>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography><h1>Oral Appliance Therapy</h1></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <div className='mouth-picture'>
    <img id='mouth-picture' alt='oral appliance' src={mouth}></img>
</div>

<h3 id='tmj-paragraph'>ORAL APPLIANCE is a custom designed mouth guard-like device that fits on the upper and/or lower teeth and repositions the jaw and tongue in a slightly forward position to keep the airway open. The oral appliance has been shown to be highly effective and successful in the treatment and management of snoring, mild to moderate obstructive sleep apnea, and some cases of severe sleep apnea. It is known as the best alternative treatment modality to patients who are intolerant to CPAP or surgery.</h3>
<h1 id='sleep-header3'>Dental Sleep Medicine</h1>
<h3 id='tmj-paragraph'>Dental Sleep Medicine is one of the most exciting medical-dental connections and is an integral part of the "sleep team". It focuses on the management of Sleep-related Breathing Disorders, which includes snoring and obstructive sleep apnea (OSA), along with oral appliance therapy and upper airway surgery. It is extremely gratifying to be able to help an astonishing 50-70 million Americans who are experiencing sleep-related breathing disorders, people who are undiagnosed, or untreated to breath better, sleep better, and to see their quality of life improve greatly by providing an effective and comfortable treatment modality.
Dental Sleep Medicine is practiced by dentists, however, it is more than general dentistry. </h3>
<h3 id='tmj-paragraph'>
Oral Appliance Therapy should be performed by qualified dentists, who have extended education, knowledge, and hands-on training. The American Academy of Sleep Medicine (www.aasmnet.org) recommends oral appliance therapy practitioners are those who are Board Certified as Diplomates of the American Board of Dental Sleep Medicine or others who have undertaken comprehensive training in sleep medicine and/or sleep-related breathing disorders with an emphasis on the scientific literature and the use of an appropriate protocol for diagnosis, treatment, and follow-up.
The American Academy of Dental Sleep Medicine (www.aadsm.org) is a professional organization of more than 2,600 dentists worldwide. The AADSM promotes research and the clinical use of oral appliances and upper airway surgery for the treatment of sleep-related breathing disorders, such as obstructive sleep apnea (OSA) and loud snoring, and provides training and resources for dentists and other professionals who work directly with patients, and sets Standard of Care.
</h3>
<h3 id='tmj-paragraph'>
Oral Appliance Therapy involves the selection of the appliance, fabrication, fitting, adjustments, and long term follow-up care and management of potential complications such as malocclusion and temporomandibular joint dysfunction. These custom-designed oral devices are worn during sleep to reposition the mandible and tongue base anteriorly to enlarge and stabilize the oropharyngeal airway.
Dr. Lee, the director of the TMJ Head & Neck Pain Center, is certified as a Diplomate from the American Board of Dental Sleep Medicine. This certification as a Diplomate represents a professional commitment to education, knowledge, and skill in the areas of dental sleep medicine and oral appliance therapy. Diplomates are recognized by patients, colleagues, and professional organizations in both medicine and dentistry as having met the highest level of knowledge and clinical competence in the field of dental sleep medicine.
TMJ Head & Neck Pain Center welcomes referrals from all health practitioners and individuals who wish to treat or manage loud snoring and obstructive sleep apnea with an oral appliance, who are seeking a second opinion, or who have problems with CPAP or surgery.
</h3>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography><h1>Dental Sleep Medicine</h1></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <h3 id='tmj-paragraph'>Dental Sleep Medicine is one of the most exciting medical-dental connections and is an integral part of the "sleep team". It focuses on the management of Sleep-related Breathing Disorders, which includes snoring and obstructive sleep apnea (OSA), along with oral appliance therapy and upper airway surgery. It is extremely gratifying to be able to help an astonishing 50-70 million Americans who are experiencing sleep-related breathing disorders, people who are undiagnosed, or untreated to breath better, sleep better, and to see their quality of life improve greatly by providing an effective and comfortable treatment modality.
Dental Sleep Medicine is practiced by dentists, however, it is more than general dentistry. </h3>
<h3 id='tmj-paragraph'>
Oral Appliance Therapy should be performed by qualified dentists, who have extended education, knowledge, and hands-on training. The American Academy of Sleep Medicine (www.aasmnet.org) recommends oral appliance therapy practitioners are those who are Board Certified as Diplomates of the American Board of Dental Sleep Medicine or others who have undertaken comprehensive training in sleep medicine and/or sleep-related breathing disorders with an emphasis on the scientific literature and the use of an appropriate protocol for diagnosis, treatment, and follow-up.
The American Academy of Dental Sleep Medicine (www.aadsm.org) is a professional organization of more than 2,600 dentists worldwide. The AADSM promotes research and the clinical use of oral appliances and upper airway surgery for the treatment of sleep-related breathing disorders, such as obstructive sleep apnea (OSA) and loud snoring, and provides training and resources for dentists and other professionals who work directly with patients, and sets Standard of Care.
</h3>
<h3 id='tmj-paragraph'>
Oral Appliance Therapy involves the selection of the appliance, fabrication, fitting, adjustments, and long term follow-up care and management of potential complications such as malocclusion and temporomandibular joint dysfunction. These custom-designed oral devices are worn during sleep to reposition the mandible and tongue base anteriorly to enlarge and stabilize the oropharyngeal airway.
Dr. Lee, the director of the TMJ Head & Neck Pain Center, is certified as a Diplomate from the American Board of Dental Sleep Medicine. This certification as a Diplomate represents a professional commitment to education, knowledge, and skill in the areas of dental sleep medicine and oral appliance therapy. Diplomates are recognized by patients, colleagues, and professional organizations in both medicine and dentistry as having met the highest level of knowledge and clinical competence in the field of dental sleep medicine.
TMJ Head & Neck Pain Center welcomes referrals from all health practitioners and individuals who wish to treat or manage loud snoring and obstructive sleep apnea with an oral appliance, who are seeking a second opinion, or who have problems with CPAP or surgery.
</h3>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography><h1>Snoring</h1></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <h3 id="tmj-paragraph">SNORING is the noisy sound of airflow during sleep when air rushes through a narrow airway and vibrates the soft palate, uvula, and sides of the throat. Habitual snoring is one of the most common sleep-related breathing disorders, which affects one third of the population, estimated in about 40% of adult men and in about 24% of adult women.
While snoring seems to be just a noisy sound and harmless to the snorer, it disrupts sleep partner's quality of sleep and quality of health. This not only causes impaired social relationships and embarrassment, but often times the sleep partner may not want to sleep in the same room which in consequence increases the chance of break up or divorce.
</h3>
<h3 id="tmj-paragraph">
Habitual loud snoring is an early indication of a potentially life threatening serious medical condition called "Obstructive Sleep Apnea" and it is highly recommended to have a consultation with a physician for sleep study.
Traditionally, there was no easy treatment method to stop snoring, except surgically removing part of the soft tissue and uvula in the mouth or insert small plastic pillar in the soft palate to stiffen the tissue. The good news is that modern dentistry has figured out how to stop snoring and/or reduce the noise dramatically in a very simple and easy way: (Refer to Oral Appliance Therapy)
</h3>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography><h1>Diagnosis</h1></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <h3 id='tmj-paragraph'>To define Sleep-related Breathing Disorder a Sleep Study is required and interpretation and diagnosis has to be done by a Board Certified physician. There are different sleep study options available as to where you have the sleep study performed and how comprehensive it may be.
</h3>
<h3 id='tmj-paragraph'>
<strong>POLYSOMNOGRAM PSG:</strong> A sophisticated instrumentation to record the body's various activities during sleep. It is the best method to diagnose sleep-related breathing disorders: measures and records the AHI, RDI, the brain waves and activity, heart rate, pulse rate, body movement, movement of eyes, length of sleep, sleep stages, number of arousals, number of apneic episodes, volume of nasal and oral airflow, loudness of snoring, unusual leg and arm movement, oxygen saturation in the blood, cardiovascular abnormalities during sleep, etc.
</h3>
<h3 id='tmj-paragraph'>
<strong>Home Sleep Study:</strong> due to advances in computer science and sleep technology, sleep study can now be performed by an ambulatory device at home where the patient feels more comfortable and can sleep without putting multiple testing electrodes around the face and body. 
</h3>
<h3 id='tmj-paragraph'>
This is a simple version of PSG and provides essential data: loudness of snoring, severity of sleep apnea (AHI RDI), blood oxygen saturation, pulse rate, body movement and position while sleeping, and identifies REM and Non-REM sleep stages etc.
</h3>
<h3 id='tmj-paragraph'>
<strong>Definition:</strong>
Apnea= cessation of breathing, during sleep for 10 seconds and more
Hypopnea= 50% decrease in airflow for 10 seconds or greater with a decrease in oxygen saturation of 4%, or an arousal
AHI Apnea/Hypopnea Index = apnea plus hypopnea per hour during sleep
RDI Respiratory Disturbance Index = AHI + snoring + RERA Respiratory Effort Related Arousal
SaO2 scale= oxygen saturation in the blood.  89% nomal  80-89% moderate  80% severe
REM= rapid eye movement - dreaming stage of sleep
NREM = regular sleep stage of sleep
</h3>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography><h1>Sleep Hygiene</h1></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <h3 id='tmj-paragraph'>Go to sleep and wake up at regular hours. A routine in bedtime and waking-up hours is of great importance.
According to research, the best sleep time is between 10:00 pm to 6:00 am and the most effective hours of sleep is 7-8 hours.
</h3>
<h3 id='tmj-paragraph'>
Maintain a quiet, dark and preferably cool, but comfortable sleep environment.
Remove noisy and bright clocks from your bedroom: ticking and luminous dials can easily prevent you from falling asleep.
</h3>
<h3 id='tmj-paragraph'>
Avoid physical activity in the late evening hours. Exercise should be done at least 2 hours before going to bed.
Avoid drinking coffee, alcohol and smoking close to bedtime.
Do not eat a heavy meal or drink any beverages excessively before going to bed.
Do not sleep during the day except for a short nap.
</h3>
<h3 id='tmj-paragraph'>
Take warm baths, listen to soft music or engage in other relaxing activities leading up to bedtime.
If you wake up during sleep, do not try to force yourself back to sleep. The more you try to fall asleep, the more your arousal level will increase, and falling asleep will become even more difficult.
</h3>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div id='scroll-up-button'>
            <FaArrowCircleUp onClick={scrollToTop}/>
        </div>
        <div>
            <Footer/>
        </div>
        </>
    );
}